import * as React from "react"
import {Link} from "gatsby"
import {Layout} from "../components/Layout"
import {StaticImage} from "gatsby-plugin-image";


const NotFoundPage = () => {
    return (
        <Layout>
            <div className="mt-24 md:grid md:grid-cols-2 lg:content-between gap-16 ">
                <div className={"md:col-span-2"}>
                    <div
                        className={"md:order-1 text-5xl md:text-6xl order-4 md:order-4 md:mt-28 mb-10 leading-normal md:leading-normal"}>
                        Oeps <span className={"font-bold"}>die is er niet</span> ... (aka 404)
                    </div>
                </div>

                {/* Professionaliteit */}
                <div className={"col-span-2 md:grid md:grid-cols-2 gap-16"}>
                    <div className={"ml-auto"}>
                        <StaticImage src={"../images/sbs-thinking-emoji.jpg"} alt={"Pagina niet gevonden"}
                                     className={"rounded-3xl shadow-lg bg-white shadow-gray-400"}/>
                    </div>
                    <div className={"h-full"}>
                        <div className={"text-4xl md:text-5xl font-bold my-10"}>Pagina niet gevonden</div>
                        <div className={"text-2xl md:text-3xl leading-relaxed md:leading-relaxed mb-16"}>
                            De pagina die je probeert te openen bestaat niet.
                        </div>
                        <div className={"text-2xl md:text-3xl leading-relaxed md:leading-relaxed mb-16"}>
                            Kies een pagina in de menubalk of neem contact via het mail of het WhatsApp icoon rechts
                            onderin het scherm ...
                        </div>
                    </div>
                </div>
            </div>
            {/*  we couldn’t find what you were looking for.*/}
            {/*  <br/>*/}
            {/*  {process.env.NODE_ENV === "development" ? (*/}
            {/*    <>*/}
            {/*      <br/>*/}
            {/*      Try creating a page in <code style={codeStyles}>src/pages/</code>.*/}
            {/*      <br/>*/}
            {/*    </>*/}
            {/*  ) : null}*/}
            {/*  <Link to="/">Go home</Link>.*/}
            {/*  <br/>*/}
            {/*</p>*/}
        </Layout>
    )
}

export default NotFoundPage
